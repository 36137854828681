<template>
    <div class="modulo-email-page">
        <Breadcrumb titulo="Formualarios" :items="itemsBreadcrumb" backgroundColor="#259cd3" />

        <div class="modulo-formularios-cartoes">
            <div class="modulo-formulario-page-cartoes"
                 v-for="cartao in cartoes"
                 :key="cartao.id"
                 v-if="cartao.canal === null || integracoesHabilitadasEmpresa.includes(cartao.canal)">

                <router-link :to="cartao.link" class="modulos-formulario-page-router-link">
                    <cartao-dinamico :titulo="cartao.titulo"
                                     :subtitulo="cartao.subtitulo"
                                     :classe="cartao.classe"
                                     :cor="cartao.cor"
                                     :iconBootstrap="cartao.icon_bootstrap" />
                </router-link>

            </div>
        </div>

    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import CartaoDinamico from '@/components/cartao/CartaoDinamico.vue'
import { mapGetters } from 'vuex'

export default {
        name: 'CampanhasPage',
        components: {
            Breadcrumb,
            CartaoDinamico,
        },
        computed: {
            ...mapGetters({
                usuarioLogado: 'authentication/authDadosUsuario'
            }),
            modulosPermitidos() {
                return this.usuarioLogado.ModulosEmpresa.split(',');
            }
        },

        data: function () {
            return {
                textoPesquisa: '',
                itemsBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },
                    {
                        id: '2',
                        classe: 'fa fa-scroll',
                        texto: 'Formulários',
                        link: 'gerenciarformularios'
                    },
                ],
                cartoes: [
                    {
                        id: '1',
                        titulo: 'Gerenciar Formulario',
                        subtitulo: 'Configurações de formulario',
                        classe: 'fa fa-scroll',
                        link: '/formularios',
                        cor: '#259cd3',
                        iconBootstrap: 'vazio',
                        canal: '0'
                    },
                    {
                        id: '2',
                        titulo: 'Relatorio Formulario',
                        subtitulo: 'Consultas de formularios',
                        classe: 'fas fa-search',
                        link: '/formularios-relatorios',
                        cor: '#259cd3',
                        iconBootstrap: 'vazio',
                        canal: '0'
                    }
                ],
            }
        },
        computed: {
            integracoesHabilitadasEmpresa() {
                const usuarioAutenticado = JSON.parse(localStorage.getItem('login'))
                const empresa = this.$store.getters['empresasLicencas/listaEmpresas']?.find(e => e.Id === usuarioAutenticado?.empresaId);
                return empresa?.IntegracoesHabilitadas?.split(',') ?? []
            }
        },
        mounted() {
            this.$store.dispatch('empresasLicencas/buscaEmpresas')
        }
}
</script>

<style scoped>
    .modulo-formularios-cartoes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 28px 12px;
    }

    .modulo-formulario-page-cartoes {
        width: 33%;
        padding: 10px;
    }

    .modulos-formulario-page-router-link {
        text-decoration: none;
        color: inherit;
    }
</style>